import { IApplicationDetail } from '@interfaces/application-details/application-details.interface';
import { IResponseBase } from '@interfaces/generic/responses.interface';
import {
  IKycBankValidationDetail,
  IKycDetail,
  IKycExperianPrincipalDetail,
  IKycMatchInquiryDetail,
  IKycMatchLookupDetail,
  IKycOfacBusinessDetail,
  IKycOfacControllingDetail,
  IKycOfacPrincipalDetail,
  IKycSiteScanDetail,
  IKycTinCheckBusinessDetail,
  IKycTinCheckPrincipalDetail,
} from '@interfaces/kyc-details/kyc-details.interface';
import { IOfferPricingPlanData } from '@interfaces/offer-pricing/offer-pricing.interface';
import {
  EAutomationListParams,
  EDealBackend,
  EDealBank,
  EDealDetailsParams,
  EDealMerchantType,
  EGetPendindDocumentsParams,
} from '@parameters/ops-and-uw-tools/deal/deal-details.parameter';
import { EDealStatus } from '@parameters/ops-and-uw-tools/deal/deal-list.parameter';
import { EDealVarSheetListParams } from '@parameters/ops-and-uw-tools/deal/deal-var-sheet.parameter';

export interface IDealDetails {
  dealSummary: IDealDetailSummary;
  applicationInfo: IDealApplicationDetail;
  offerInfo: IDealOfferInfo;
  kycInquiries: IDealKycDetail;
}

export interface loadingInfo {
  dealLoadingInfo: boolean;
  applicationLoadingInfo: boolean;
  automatioonLoadingInfo: boolean;
}

export interface IDealDetailSummary {
  id: string;
  merchantId: string;
  offerId: string;
  status: EDealStatus;
  merchantType: EDealMerchantType;
  mid: string;
  payfacId: string;
  dealCode: string;
  legalName: string;
  dbaName: string;
  discoverAccountNumber: string;
  mccCode: string;
  agentRelationship: {
    id: string;
    name: string;
  };
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhoneNumber: string;
  backend: EDealBackend;
  bank: EDealBank;
  submittedDate: string;
  resolutionDate: string;
  resolution: string;
  sfLink: string;
  aexLink: string;
  amexWorkaroundMid: string;
  associationNumberOverride: number;
  processorBusinessName: string;
  processorLegalBusinessName: string;
  secondaryMid: string;
  accountId: string;
  payfacTerminalId: string;
}

export interface IDealDetailsResponse extends IResponseBase<IDealDetails> {}

export interface IDealDetailsParams {
  [EDealDetailsParams.filter]?: string;
  [EDealDetailsParams.id]: string;
}

export const EDealDetailsParamsDefault: IDealDetailsParams = {
  [EDealDetailsParams.filter]:
    'deal_summary,offer_info,application_info,kyc_inquiries',
  [EDealDetailsParams.id]: '',
};

export interface IAutomationListParams {
  [EAutomationListParams.merchantId]: string;
}

export interface IGetPendindDocumentsParams {
  [EGetPendindDocumentsParams.merchantId]: string;
}

export interface IGetDealVarSheetListParams {
  [EDealVarSheetListParams.dealId]: string;
}

export interface IDealAutomationItem {
  taskId: string;
  title: string;
  description: string;
  taskType: EnumAutomationTaskType;
  currentStatus: EnumAutomationStatus;
  taskLogs: ITaskLog[];
  errorLogs: string[];
}

export interface IDealVarsheetItem {
  varsheetId: string;
  terminalType: string;
  vNumber: string;
  createdOn: string;
  createdBy: string;
}

export interface IDealVarsheetList {
  varsheetList: IDealVarsheetItem[];
}

export interface IMerchantPendingDoc {
  description: string;
  id: string;
}

export interface IMerchantPendingDocument {
  merchantPendingDocs: IMerchantPendingDoc[];
}

export interface ITaskLog {
  description: string;
  createdDate: string;
  status: EnumAutomationTaskLogStatus;
}

export enum EnumAutomationTaskLogStatus {
  CreatedAutomatically = 'created_automatically',
  Started = 'started',
  Completed = 'completed',
  Failed = 'failed',
  Skipped = 'skipped',
  // from the response
  CompletedManualIntervention = 'completed_manual_intervention',
  Created = 'created',
}

export enum EnumAutomationTaskType {
  SalesforceIntegration = 'salesforce_integration',
  MerchantScoring = 'merchant_scoring',
  DataValidation = 'data_validation',
  LinkMerchant = 'link_merchant',
  BoardToMms = 'board_to_mms',
  BoardToTsys = 'board_to_tsys',
  BoardToPpm = 'board_to_ppm',
  UpdateStatusToBoarded = 'update_status_to_boarded',
  CreateVarTerminal = 'create_var_terminal',
  SendVarSheet = 'send_var_sheet',
  UpdateStatusToCompleted = 'update_status_to_deployed',
}

export enum EnumAutomationStatus {
  Completed = 'completed', // hide
  InProgress = 'in_progress', // hide
  Failed = 'failed',
  NotStarted = 'not_started',
}

export const CAutomationStatusColor = {
  [EnumAutomationStatus.Completed]: 'success',
  [EnumAutomationStatus.InProgress]: 'secondary',
  [EnumAutomationStatus.Failed]: 'danger',
  [EnumAutomationStatus.NotStarted]: 'dark',
};

export const CAutomationStatusName = {
  [EnumAutomationStatus.Completed]: 'Completed',
  [EnumAutomationStatus.InProgress]: 'In Progress',
  [EnumAutomationStatus.Failed]: 'Failed',
  [EnumAutomationStatus.NotStarted]: 'Not Started',
};

export enum EAutomationAction {
  Manual = 'manual',
  Rerun = 'rerun',
}

export interface IDealAutomationListResponse
  extends IResponseBase<IDealAutomationItem[]> {}
export interface IDealApplicationDetail extends IApplicationDetail {}

export interface IDealKycDetail extends IKycDetail {}

export interface IDealKycSiteScanDetail extends IKycSiteScanDetail {}

export interface IDealKycOfacControllingDetail
  extends IKycOfacControllingDetail {}

export interface IDealKycOfacBusinessDetail extends IKycOfacBusinessDetail {}

export interface IDealKycOfacPrincipalDetail extends IKycOfacPrincipalDetail {}

export interface IDealKycBankValidationDetail
  extends IKycBankValidationDetail {}

export interface IDealKycTinCheckBusinessDetail
  extends IKycTinCheckBusinessDetail {}

export interface IDealKycTinCheckPrincipalDetail
  extends IKycTinCheckPrincipalDetail {}

export interface IDealKycExperianPrincipalDetail
  extends IKycExperianPrincipalDetail {}

export interface IDealKycMatchInquiryDetail extends IKycMatchInquiryDetail {}

export interface IDealKycMatchLookupDetail extends IKycMatchLookupDetail {}

export interface IDealOfferInfo extends IOfferPricingPlanData {}

export interface IDealVarSheetListResponse
  extends IResponseBase<IDealVarsheetList> {}

export interface IMerchantPendingDocumentResponse
  extends IResponseBase<IMerchantPendingDocument> {}

export enum EDealTabKeys {
  application = 'application',
  offerDetails = 'offerDetails',
  equipment = 'equipments',
  uploads = 'uploads',
  notes = 'notes',
  configurations = 'configurations',
  kycIntegrations = 'kycIntegrations',
  automations = 'automations',
  infoRequests = 'infoRequests',
}

export const EDealTabLabels = {
  [EDealTabKeys.application]: 'Application',
  [EDealTabKeys.offerDetails]: 'Offer Details',
  [EDealTabKeys.equipment]: 'Equipment',
  [EDealTabKeys.uploads]: 'Uploads',
  [EDealTabKeys.notes]: 'Notes',
  [EDealTabKeys.configurations]: 'Configurations',
  [EDealTabKeys.kycIntegrations]: 'KYC Integrations',
  [EDealTabKeys.automations]: 'Automations',
  [EDealTabKeys.infoRequests]: 'Info Requests',
};

export const CDealDetailTabs = {
  [EDealTabKeys.application]: {
    label: EDealTabLabels[EDealTabKeys.application],
    // active: false,
  },
  [EDealTabKeys.offerDetails]: {
    label: EDealTabLabels[EDealTabKeys.offerDetails],
    // active: false,
  },
  [EDealTabKeys.equipment]: {
    label: EDealTabLabels[EDealTabKeys.equipment],
    // active: false,
  },
  [EDealTabKeys.uploads]: {
    label: EDealTabLabels[EDealTabKeys.uploads],
    // active: false,
  },
  [EDealTabKeys.notes]: {
    label: EDealTabLabels[EDealTabKeys.notes],
    // active: false,
  },
  [EDealTabKeys.configurations]: {
    label: EDealTabLabels[EDealTabKeys.configurations],
    // active: false,
  },
  [EDealTabKeys.kycIntegrations]: {
    label: EDealTabLabels[EDealTabKeys.kycIntegrations],
    // active: false,
  },
  [EDealTabKeys.automations]: {
    label: EDealTabLabels[EDealTabKeys.automations],
    // active: false,
  },
  [EDealTabKeys.infoRequests]: {
    label: EDealTabLabels[EDealTabKeys.infoRequests],
    // active: false,
  },
};

export enum EDealApplicationKeys {
  businessInfo = 'businessInfo',
  processingInfo = 'processingInfo',
  bankingInfo = 'bankingInfo',
  motoQuestionnaire = 'motoQuestionnaire',
  businessPrincipals = 'businessPrincipals',
  cardBrandElections = 'cardBrandElections',
  signatures = 'signatures',
  securityInformation = 'securityInformation',
  achProcessingInfo = 'achProcessingInfo',
}

export enum EDealKYCKeys {
  kycSitescan = 'kycSItescan',
  bankValidations = 'bankValidations',
  tinCheckBusiness = 'tinCheckBusiness',
  tinCheckPrincipal = 'tinCheckPrincipal',
  experianCreditCheck = 'experianCreditCheck',
  matchInquiry = 'matchInquiry',
  matchLookup = 'matchLookup',
  ofacLookup = 'ofacLookup',
}

export interface IDealAllowedStatusList {
  nextStatusesAllowed: IDealAllowedStatus[];
}

export interface IDealAllowedStatus {
  status: EDealStatus;
}
