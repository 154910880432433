import {
  IProspectInfromtationDocs,
  IProspectsInformationRequest,
} from '@interfaces/prospects/prospect-information.interface';
import {
  IProspectsDocumentUpload,
  IProspectsDocumentUploadResponse,
} from '@interfaces/prospects/prospects-uploads.interface';
import { ResponsePagination } from '@models/generic/responses.model';

export class ProspectsInformationRequest {
  public uwInfoRequestId: string;
  public uwComment: string;
  public requestedBy: string;
  public status: string;
  public requestedOn?: string;
  public docsRequested?: IProspectInfromtationDocs[];
  public responses: [];
  constructor(iProspectsInfoReq: IProspectsInformationRequest) {
    this.uwInfoRequestId = iProspectsInfoReq?.uwInfoRequestId;
    this.uwComment = iProspectsInfoReq?.uwComment;
    this.requestedBy = iProspectsInfoReq?.requestedBy;
    this.requestedOn = iProspectsInfoReq?.requestedOn;
    this.status = iProspectsInfoReq?.status;
    this.docsRequested = iProspectsInfoReq?.docsRequested;
    this.responses = iProspectsInfoReq?.responses;
  }
}

export class ProspectsDocumentUpload {
  public id: string;
  public description: string;
  public documentName: string;
  public documentType: string;
  public uploadedDate: string;

  constructor(iProspectsDocumentUpload: IProspectsDocumentUpload) {
    this.id = iProspectsDocumentUpload?.id;
    this.description = iProspectsDocumentUpload?.documentType;
    this.documentName = iProspectsDocumentUpload?.documentName;
    this.documentType = iProspectsDocumentUpload?.documentTypeId;
    this.uploadedDate = iProspectsDocumentUpload?.uploadedDate;
  }
}

export class ProspectsDocumentUploadList {
  pagination: ResponsePagination;
  list: ProspectsDocumentUpload[];

  constructor(res: IProspectsDocumentUploadResponse) {
    this.pagination = new ResponsePagination(res?.data?.summary);
    this.list = res?.data?.rows?.length
      ? res?.data?.rows?.map(
          (document) => new ProspectsDocumentUpload(document)
        )
      : [];
  }
}
