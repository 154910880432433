import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericClientService } from '@clients/generic/generic-client.service';
import { GenericHelper } from '@helpers/generic.helper';
import { IApplicationDetail } from '@interfaces/application-details/application-details.interface';
import {
  IEquipmentTerminalDetail,
  IPaymentGatewayList,
} from '@interfaces/equipment-details/equipment-terminal.interface';
import {
  IReportsKycCSVResponse,
  IResponseBase,
} from '@interfaces/generic/responses.interface';
import {
  IAddOfferPricing,
  IAddOfferPricingResponse,
} from '@interfaces/offer-pricing/offer-pricing.interface';
import { IDealCreateResponse } from '@interfaces/ops-and-uw-tools/deal/deal-create.interface';
import {
  EDealDetailsParamsDefault,
  IAutomationListParams,
  IDealAllowedStatusList,
  IDealAutomationListResponse,
  IDealDetailsParams,
  IDealDetailsResponse,
  IDealVarSheetListResponse,
  IGetDealVarSheetListParams,
  IGetPendindDocumentsParams,
  IMerchantPendingDocumentResponse,
} from '@interfaces/ops-and-uw-tools/deal/deal-details.interface';
import {
  IDealEquipmentParams,
  IDealPaymentGatewayResponse,
  IDealPhysicalTerminalResponse,
  IDealTerminalCalculationResponse,
  IDealUpdateEquipments,
  IDealUpdatePaymentGateway,
  TDealDeleteEquipment,
} from '@interfaces/ops-and-uw-tools/deal/deal-equipment.interface';
import {
  CReportsDealListParamsDefault,
  IDealListResponse,
  IReportsDealListParams,
} from '@interfaces/ops-and-uw-tools/deal/deal-list.interface';
import {
  CDealNotesListParamsDefault,
  ICreateDealNoteFormValue,
  IDealCreateNoteResponse,
  IDealNoteDetailResponse,
  IDealNotesListParams,
  IDealNotesListResponse,
} from '@interfaces/ops-and-uw-tools/deal/deal-note.interface';
import {
  IDealDocumentUploadResponse,
  IDealRequiredDocumentResponse,
  IDealUploadParams,
  IDealUploadRequiredDocument,
} from '@interfaces/ops-and-uw-tools/deal/deal-upload.interface';
import { IDealInformationRequestResponse } from '@interfaces/ops-and-uw-tools/deal/dela-info-req.interface';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';
import { IDealAutomationRequest } from '@parameters/ops-and-uw-tools/deal/deal-automation.parameter';
import {
  EAutomationListParams,
  EBoardingConfigUpdateRequest,
  EDealDetailsParams,
  EDealUpdateStatusRequest,
  EDeploymentConfigUpdateRequest,
  EGetPendindDocumentsParams,
} from '@parameters/ops-and-uw-tools/deal/deal-details.parameter';
import {
  CDealEquipmentParams,
  EDealEquipmentParams,
} from '@parameters/ops-and-uw-tools/deal/deal-equipment.parameter';
import { EReportsDealListParams } from '@parameters/ops-and-uw-tools/deal/deal-list.parameter';
import { EDealNotesListParams } from '@parameters/ops-and-uw-tools/deal/deal-notes.parameter';
import {
  CDealUploadParams,
  EDealUploadParams,
} from '@parameters/ops-and-uw-tools/deal/deal-upload.parameter';
import {
  EDealVarSheetListParams,
  IDealCreateVarSheetRequest,
  IDealSendVarSheetRequest,
} from '@parameters/ops-and-uw-tools/deal/deal-var-sheet.parameter';
import { RequestAdditionalDocumentsRequest } from '@parameters/ops-and-uw-tools/deal/document-to-request.parameter';
import { Observable } from 'rxjs';
import { UserRoles } from 'src/app/auth/models';
import { AuthenticationService } from 'src/app/auth/service';
import { ICreateDealFormValue } from 'src/app/business/portal/ops-and-uw-tools/deals/components/create-deals/interfaces/create-deals-form.interface';

@Injectable()
export class DealClientService {
  public userRoels = UserRoles;

  constructor(
    private genericClient: GenericClientService,
    private _authenticationService: AuthenticationService
  ) {}

  getDealList(
    queryParams: IReportsDealListParams
  ): Observable<IDealListResponse> {
    const endpoint = EndpointsParameter.Deal;
    let params = new HttpParams()
      .set(
        EReportsDealListParams.size,
        queryParams[EReportsDealListParams.size] ||
          CReportsDealListParamsDefault[EReportsDealListParams.size]
      )
      .set(
        EReportsDealListParams.page,
        queryParams[EReportsDealListParams.page] ||
          CReportsDealListParamsDefault[EReportsDealListParams.page]
      )
      .set(
        EReportsDealListParams.sort,
        queryParams[EReportsDealListParams.sort] ||
          CReportsDealListParamsDefault[EReportsDealListParams.sort]
      )
      .set(
        EReportsDealListParams.search,
        queryParams[EReportsDealListParams.search] ||
          CReportsDealListParamsDefault[EReportsDealListParams.search]
      )
      .set(
        EReportsDealListParams.status,
        queryParams[EReportsDealListParams.status].join(',') ||
          CReportsDealListParamsDefault[EReportsDealListParams.status].join(',')
      )
      .set(
        EReportsDealListParams.relationshipId,
        queryParams[EReportsDealListParams.relationshipId].join(',') ||
          CReportsDealListParamsDefault[
            EReportsDealListParams.relationshipId
          ].join(',')
      );

    if (
      queryParams[EReportsDealListParams.startDate] &&
      queryParams[EReportsDealListParams.endDate]
    ) {
      params = params
        .append(
          EReportsDealListParams.startDate,
          queryParams[EReportsDealListParams.startDate] ||
            CReportsDealListParamsDefault[EReportsDealListParams.startDate]
        )
        .append(
          EReportsDealListParams.endDate,
          queryParams[EReportsDealListParams.endDate] ||
            CReportsDealListParamsDefault[EReportsDealListParams.endDate]
        );
    }
    return this.genericClient.genericGet<IDealListResponse>(endpoint, {
      params,
    });
  }

  getDealDetail(queryParams: IDealDetailsParams): Observable<any> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealDetails,
      queryParams[EDealDetailsParams.id]
    );
    const params = new HttpParams().set(
      EDealDetailsParams.filter,
      this.validateRole(this.userRoels.GettrxAdmin) ||
        this.validateRole(this.userRoels.GettrxUnderwriting) ||
        this.validateRole(this.userRoels.GettrxUnderwritingManager)
        ? EDealDetailsParamsDefault[EDealDetailsParams.filter]
        : 'deal_summary,offer_info,application_info'
    );

    return this.genericClient.genericGet<IDealDetailsResponse>(endpoint, {
      params,
    });
  }

  public validateRole(role: string) {
    return this._authenticationService.hasRole(role);
  }

  getDealKycInquiryDetail(
    dealId: string,
    inquiryId: string,
    groupId: string
  ): Observable<any> {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealKycInquiryDetails,
      dealId
    );
    endpoint = GenericHelper.replaceUrl(endpoint, inquiryId);
    const params = new HttpParams().set('group_id', groupId || '');
    return this.genericClient.genericGet<IDealDetailsResponse>(endpoint, {
      params,
    });
  }

  updateDealStatus(
    dealId: string,
    request: EDealUpdateStatusRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealDetails,
      dealId
    );
    return this.genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  updateBoardingConfiguration(
    merchantId: string,
    request: EBoardingConfigUpdateRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PathBoardingConfiguration,
      merchantId
    );
    return this.genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  updateDeploymentConfiguration(
    merchantId: string,
    request: EDeploymentConfigUpdateRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PathDeploymentConfiguration,
      merchantId
    );
    return this.genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  getPendingDocuments(queryParams: IGetPendindDocumentsParams) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.GetPendingDocuments,
      queryParams[EGetPendindDocumentsParams.merchantId]
    );

    return this.genericClient.genericGet<IMerchantPendingDocumentResponse>(
      endpoint
    );
  }

  sendRequestAdditionalDocuments(
    merchantId: string,
    request: RequestAdditionalDocumentsRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PathRequestAdditionalDocuments,
      merchantId
    );

    return this.genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  getAutomationList(queryParams: IAutomationListParams) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.AutomationsList,
      queryParams[EAutomationListParams.merchantId]
    );

    return this.genericClient.genericGet<IDealAutomationListResponse>(endpoint);
  }

  postAutomationAction(
    merchantId: string,
    request: IDealAutomationRequest
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.AutomationAction,
      merchantId
    );

    return this.genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  getKycInquiry(
    dealId: string,
    groupName: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealKycInquiry,
      dealId
    );
    let param = new HttpParams().set('group_id', groupName || '');
    param = param.append('order_by', 'created_date:desc');
    return this.genericClient.genericGet<any>(endpoint, { params: param });
  }

  postKycInquiry(
    dealId: string,
    groupId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealReRunKycInquiry,
      dealId
    );

    return this.genericClient.genericPost<IResponseBase<null>>(endpoint, {
      groupId: groupId,
    });
  }

  downloadCSV(
    dealId: string,
    inquiryId: string,
    reportId: string
  ): Observable<IReportsKycCSVResponse> {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealDownloadKycInquiry,
      dealId
    );
    endpoint = GenericHelper.replaceUrl(endpoint, inquiryId);
    endpoint = GenericHelper.replaceUrl(endpoint, reportId);
    return this.genericClient.genericGet<IReportsKycCSVResponse>(endpoint);
  }

  updateDealApplication(
    dealId: string,
    data: IApplicationDetail
  ): Observable<IResponseBase<{ dealsId: string }>> {
    const endpoint = `${EndpointsParameter.Deal}/${dealId}`;
    return this.genericClient.genericPut<IResponseBase<null>>(endpoint, data);
  }

  postCreateVarSheet(dealId: string, request: IDealCreateVarSheetRequest) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.CreateVarSheet,
      dealId
    );

    return this.genericClient.genericPost<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  getDealVarSheetList(queryParams: IGetDealVarSheetListParams) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.VarSheetList,
      queryParams[EDealVarSheetListParams.dealId]
    );

    return this.genericClient.genericGet<IDealVarSheetListResponse>(endpoint);
  }

  deleteVarsheet(dealId: string, varsheetId: string) {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DeleteVarSheet,
      dealId
    );
    endpoint = GenericHelper.replaceUrl(endpoint, varsheetId);

    return this.genericClient.genericDelete<IResponseBase<null>>(endpoint);
  }

  sendVarSheet(
    dealId: string,
    varsheetId: string,
    request: IDealSendVarSheetRequest
  ) {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DeleteVarSheet,
      dealId
    );
    endpoint = GenericHelper.replaceUrl(endpoint, varsheetId);

    return this.genericClient.genericPut<IResponseBase<null>>(
      endpoint,
      request
    );
  }

  getDealAllowedStatus(
    dealId: string
  ): Observable<IResponseBase<IDealAllowedStatusList>> {
    let endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealAllowedStatus,
      dealId
    );

    return this.genericClient.genericGet<IResponseBase<IDealAllowedStatusList>>(
      endpoint
    );
  }

  public getRequiredDocuments(
    merchantId: string,
    params: { search: string }
  ): Observable<IDealRequiredDocumentResponse> {
    const param = new HttpParams().set('search', params?.search || '');

    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealRequiredDocument,
      merchantId
    );

    return this.genericClient.genericGet<IDealRequiredDocumentResponse>(
      endpoint,
      { params: param }
    );
  }

  public getInfoRequests(
    merchantId: string,
    params: { search: string }
  ): Observable<IDealInformationRequestResponse> {
    const param = new HttpParams().set('search', params?.search || '');

    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealInformationRequests,
      merchantId
    );

    return this.genericClient.genericGet<IDealInformationRequestResponse>(
      endpoint,
      { params: param }
    );
  }

  public getDocumentUploads(
    merchantId: string,
    params: IDealUploadParams
  ): Observable<IDealDocumentUploadResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealDocumentUploads,
      merchantId
    );

    let param = new HttpParams()
      .set(
        EDealUploadParams.page,
        params[EDealUploadParams.page] ||
          CDealUploadParams[EDealUploadParams.page]
      )
      .set(
        EDealUploadParams.limit,
        params[EDealUploadParams.limit] ||
          CDealUploadParams[EDealUploadParams.limit]
      )
      .set(
        EDealUploadParams.search,
        params[EDealUploadParams.search] ||
          CDealUploadParams[EDealUploadParams.search]
      )
      .set(
        EDealUploadParams.sort,
        params[EDealUploadParams.sort].join(',') ||
          CDealUploadParams[EDealUploadParams.sort].join(',')
      );

    return this.genericClient.genericGet<IDealDocumentUploadResponse>(
      endpoint,
      { params: param }
    );
  }

  public uploadRequiredDocuments(
    docs: IDealUploadRequiredDocument,
    merchantId: string
  ): Observable<IResponseBase<void>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealUploadDocuments,
      merchantId
    );

    return this.genericClient.genericPost<IResponseBase<void>>(endpoint, docs);
  }

  public deleteRequiredDocument(
    merchantId: string,
    documentId: string
  ): Observable<IResponseBase<void>> {
    const url = GenericHelper.replaceUrl(
      EndpointsParameter.DealUploadDocuments,
      merchantId
    );
    const endpoint = `${url}/${documentId}`;
    return this.genericClient.genericDelete(endpoint);
  }

  /**
   * Get All Equipment Terminals
   * @param merchantId
   * @returns
   */
  public getEquipmentTerminalsList(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealEquipmentTerminal,
      merchantId
    );

    return this.genericClient.genericGet<IEquipmentTerminalDetail[]>(endpoint);
  }

  /**
   * Get All Payment Gateways
   * @param merchantId
   * @returns
   */
  public getPaymentGatewaysList(merchantId: string) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealVirtualGateways,
      merchantId
    );

    return this.genericClient.genericGet<IResponseBase<IPaymentGatewayList[]>>(
      endpoint
    );
  }

  /**
   * Get Selected Physical Terminal
   * @param dealId
   * @returns
   */
  public getSelectedEquipmentTerminals(
    dealId: string,
    params: IDealEquipmentParams,
    isPaginated: boolean = true
  ): Observable<IDealPhysicalTerminalResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedEquipmentTerminal,
      dealId
    );

    let httpParams = new HttpParams();
    if (isPaginated) {
      httpParams = httpParams.append(
        EDealEquipmentParams.page,
        params?.page || CDealEquipmentParams[EDealEquipmentParams.page]
      );
      httpParams = httpParams.append(
        EDealEquipmentParams.limit,
        params?.limit || CDealEquipmentParams[EDealEquipmentParams.limit]
      );
    }
    return this.genericClient.genericGet<IDealPhysicalTerminalResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Get Selected Payment Gateways
   * @param dealId
   * @returns
   */
  public getSelectedPaymentGateways(
    dealId: string,
    params: IDealEquipmentParams
  ): Observable<IDealPaymentGatewayResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedVirtualGateways,
      dealId
    );

    const httpParams = new HttpParams()
      .set(
        EDealEquipmentParams.page,
        params?.page || CDealEquipmentParams[EDealEquipmentParams.page]
      )
      .set(
        EDealEquipmentParams.limit,
        params?.limit || CDealEquipmentParams[EDealEquipmentParams.limit]
      );

    return this.genericClient.genericGet<IDealPaymentGatewayResponse>(
      endpoint,
      { params: httpParams }
    );
  }

  /**
   * Update Payment Gateway
   * @param payload
   * @param dealId
   * @returns
   */
  public updatePaymentGateway(
    payload: IDealUpdatePaymentGateway,
    dealId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedVirtualGateways,
      dealId
    );

    return this.genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Delete Payment Gateway
   * @param gatewayId
   * @param dealId
   * @returns
   */
  public deletePaymentGateway(
    gatewayId: string,
    dealId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedVirtualGateways,
      dealId
    );

    return this.genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${gatewayId}`
    );
  }

  /**
   * Update Equipment
   * @param payload
   * @param eealId
   */
  public updateEquipments(
    payload: IDealUpdateEquipments,
    dealId: string
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedEquipmentTerminal,
      dealId
    );

    return this.genericClient.genericPatch<IResponseBase<null>>(
      endpoint,
      payload
    );
  }

  /**
   * Delete Equipment
   * @param dealId
   * @param type
   */
  public deleteEquipment(
    dealId: string,
    pid: string,
    type: TDealDeleteEquipment
  ): Observable<IResponseBase<null>> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealSelectedEquipmentTerminal,
      dealId
    );

    const params = new HttpParams().set('type', type);

    return this.genericClient.genericDelete<IResponseBase<null>>(
      `${endpoint}/${pid}`,
      {
        params: params,
      }
    );
  }

  /**
   * Get Terminal Calculations data
   * @param dealId
   * @returns
   */
  public dealTerminalCalculation(
    dealId: string
  ): Observable<IDealTerminalCalculationResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealTerminalCalculation,
      dealId
    );
    return this.genericClient.genericGet<IDealTerminalCalculationResponse>(
      endpoint
    );
  }

  /**
   * Add/Update Offer Pricing
   * @param data
   * @param dealId
   * @param isEdit @default false
   * @returns
   */
  public updateOfferPricing(
    data: IAddOfferPricing,
    dealId: string,
    isEdit: boolean = false
  ): Observable<IAddOfferPricingResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealOfferPricing,
      dealId
    );
    if (isEdit) {
      return this.genericClient.genericPatch<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    } else {
      return this.genericClient.genericPost<IAddOfferPricingResponse>(
        endpoint,
        data
      );
    }
  }

  /**
   * Create New Deal
   * @param body
   * @returns
   */
  public createNewDeal(
    body: ICreateDealFormValue
  ): Observable<IDealCreateResponse> {
    const endpoint = EndpointsParameter.Deal;
    return this.genericClient.genericPost<IDealCreateResponse>(endpoint, {
      prospect: body,
    });
  }

  /**
   * Create/Update deal Note
   * @param body
   * @param dealId
   * @returns
   */
  public createNewNote(
    body: ICreateDealNoteFormValue,
    dealId: string,
    isEdit: boolean = false,
    noteId?: string
  ): Observable<IDealCreateNoteResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealNotes,
      dealId
    );

    if (isEdit) {
      return this.genericClient.genericPatch<IDealCreateNoteResponse>(
        `${endpoint}/${noteId}`,
        body
      );
    } else {
      return this.genericClient.genericPost<IDealCreateNoteResponse>(
        endpoint,
        body
      );
    }
  }

  /**
   * Get list of all Notes for deal
   * @param dealId
   * @returns
   */
  public getNotesList(
    dealId: string,
    params: IDealNotesListParams
  ): Observable<IDealNotesListResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealNotes,
      dealId
    );
    const httpParams = new HttpParams()
      .set(
        EDealNotesListParams.page,
        params[EDealNotesListParams.page] ||
          CDealNotesListParamsDefault[EDealNotesListParams.page]
      )
      .set(
        EDealNotesListParams.limit,
        params[EDealNotesListParams.limit] ||
          CDealNotesListParamsDefault[EDealNotesListParams.limit]
      )
      .set(
        EDealNotesListParams.search,
        params[EDealNotesListParams.search] ||
          CDealNotesListParamsDefault[EDealNotesListParams.search]
      )
      .set(
        EDealNotesListParams.sort,
        params[EDealNotesListParams.sort]?.join(',') || ''
      );
    return this.genericClient.genericGet<IDealNotesListResponse>(endpoint, {
      params: httpParams,
    });
  }

  /**
   * Get detail of single deal Note
   * @param dealId
   * @param noteId
   * @returns
   */
  public getNotesDetail(
    dealId: string,
    noteId: string
  ): Observable<IDealNoteDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealNotes,
      dealId
    );
    return this.genericClient.genericGet<IDealNoteDetailResponse>(
      `${endpoint}/${noteId}`
    );
  }

  /**
   * Delete existing deal note
   * @param dealId
   * @param noteId
   * @returns
   */
  public deleteDealNote(
    dealId: string,
    noteId: string
  ): Observable<IDealNoteDetailResponse> {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.DealNotes,
      dealId
    );
    return this.genericClient.genericDelete<IDealNoteDetailResponse>(
      `${endpoint}/${noteId}`
    );
  }
}
