import {
  IResponseBase,
  IResponseData,
} from '@interfaces/generic/responses.interface';

import {
  CDepositListParamsDate,
  EDepositCSVBody,
  EDepositListParams,
  EDepositListParamsSort,
} from '@parameters/reports/deposits/deposit-list.parameter';
import { IDeposit } from '@interfaces/reports/deposit/deposit.interface';
import { EDepositSource } from '@parameters/reports/deposits/deposit.parameter';

export interface IDepositListParams {
  [EDepositListParams.type]: string;
  [EDepositListParams.midList]: string[];
  [EDepositListParams.binList]: string[];
  [EDepositListParams.limit]: number;
  [EDepositListParams.page]: number;
  [EDepositListParams.sort]: EDepositListParamsSort;
  [EDepositListParams.search]: string;
  [EDepositListParams.startDate]: string;
  [EDepositListParams.endDate]: string;
  [EDepositListParams.sources]: EDepositSource[];
}

export interface IDepositListResponse
  extends IResponseBase<IResponseData<IDeposit>> {}

export const CDepositListParamsDefault: IDepositListParams = {
  [EDepositListParams.midList]: [],
  [EDepositListParams.binList]: [],
  [EDepositListParams.type]: '',
  [EDepositListParams.limit]: 10,
  [EDepositListParams.page]: 1,
  [EDepositListParams.sort]: EDepositListParamsSort.dbaAsc,
  [EDepositListParams.search]: '',
  [EDepositListParams.startDate]: CDepositListParamsDate.value.startDate,
  [EDepositListParams.endDate]: CDepositListParamsDate.value.endDate,
  [EDepositListParams.sources]: [],
};

// Download CSV Params
export interface IDepositCSVBody {
  [EDepositCSVBody.midList]: string[];
  [EDepositCSVBody.binList]: string[];
  [EDepositCSVBody.search]: string;
  [EDepositCSVBody.startDate]: string;
  [EDepositCSVBody.endDate]: string;
  [EDepositCSVBody.sources]: EDepositSource[];
}

export interface IDepositCSVResponse extends IResponseBase<{ url: string }> {}

export const CDepositCSVBodyDefault: IDepositCSVBody = {
  [EDepositCSVBody.midList]: null,
  [EDepositCSVBody.binList]: null,
  [EDepositCSVBody.search]: null,
  [EDepositCSVBody.startDate]: CDepositListParamsDate.value.startDate,
  [EDepositCSVBody.endDate]: CDepositListParamsDate.value.endDate,
  [EDepositCSVBody.sources]: [],
};
