import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { GenericClientService } from '@clients/generic/generic-client.service';
import { EndpointsParameter } from '@parameters/http/endpoints.parameter';

import {
  IStateListParams,
  IStateListResponse,
  CStateParamsDefault,
} from '@atoms/state-select/interface/states.interface';
import { EStateListParams } from '@atoms/state-select/parameter/state.parameter';
import {
  EEquipmentUploadType,
  S3SignedUrlRequest,
} from '@parameters/core/s3.request';
import { ISignedS3Response } from '@interfaces/core/s3.interface';
import { GenericHelper } from '@helpers/generic.helper';
import {
  CCountryListParamsDefault,
  ECountryListParams,
} from '@parameters/core/country.parameter';
import {
  ICountryListParams,
  ICountryListResponse,
} from '@interfaces/core/country.interface';

@Injectable()
export class CoreClientService {
  private endpoint: string = EndpointsParameter.CoreStates;
  constructor(private genericClient: GenericClientService) {}

  /**
   * Get state list
   * @param queryParams
   * @returns <IStateListResponse> respose from server.
   */
  getCoreStateList(
    queryParams: IStateListParams
  ): Observable<IStateListResponse> {
    const httpParams = new HttpParams().set(
      EStateListParams.country,
      queryParams[EStateListParams.country] ||
        CStateParamsDefault[EStateListParams.country]
    );

    return this.genericClient.genericGet<IStateListResponse>(this.endpoint, {
      params: httpParams,
    });
  }

  postS3SignedUrl(merchantId: string, request: S3SignedUrlRequest) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PostS3Image,
      merchantId
    );
    return this.genericClient.genericPost<ISignedS3Response>(endpoint, request);
  }

  postPublicS3SignedUrl(
    accountId: string,
    request: S3SignedUrlRequest,
    requestType: EEquipmentUploadType
  ) {
    const endpoint = GenericHelper.replaceUrl(
      EndpointsParameter.PublicPostS3Image,
      accountId
    );
    request['type'] = requestType;
    return this.genericClient.genericPost<ISignedS3Response>(endpoint, request);
  }

  /**
   * Get country list
   * @param queryParams
   * @returns <ICountryListResponse> respose from server.
   */
  getCoreCountryList(
    queryParams: ICountryListParams
  ): Observable<ICountryListResponse> {
    const endpoint: string = EndpointsParameter.CoreCountries;

    const httpParams = new HttpParams()
      .set(
        ECountryListParams.page,
        queryParams.page || CCountryListParamsDefault[ECountryListParams.page]
      )
      .set(
        ECountryListParams.limit,
        queryParams.limit || CCountryListParamsDefault[ECountryListParams.limit]
      )
      .set(
        ECountryListParams.search,
        queryParams.search ||
          CCountryListParamsDefault[ECountryListParams.search]
      );
    return this.genericClient.genericGet<ICountryListResponse>(endpoint, {
      params: httpParams,
    });
  }
}
